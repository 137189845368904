<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SALARY AND WAGES WORKING DAYS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-4">
          <v-flex xs12 md4>
            <v-date-picker
              class="mx-2"
              :landscape="true"
              v-model="month_of"
              type="month"
              min="2024-02-01"
              @change="search_data"
              @select="search_data"
            ></v-date-picker>
            <v-select
              v-model="duration"
              class="mx-2 mt-6"
              :items="['1-15', '16-30/31']"
              label="Duration"
              required
              :rules="rules.combobox_rule"
              dense
            ></v-select>
            <v-text-field
              class="mx-2 mt-6"
              v-model="no_of_days"
              label="No. of Days"
              dense
              type="number"
              min="0"
              max="31"
            ></v-text-field>
          </v-flex>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="save_employee" v-if="!saving"> Save
              Changes
            </v-btn>
            <v-progress-circular
              :size="50"
              :width="5"
              color="primary"
              indeterminate
              v-if="saving"
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-data-table dense
                    class="mt-3"
                    :headers="headers"
                    :items="data"
                    disable-pagination
                    hide-default-footer
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.month_of }}
            </td>
            <td>
              {{ item.duration }}
            </td>
            <td>
              {{ item.no_of_days }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose, mdiPlusBox
    , mdiPencil
    , mdiAccountSearchOutline
    , mdiAccountClock
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {P} from 'caniuse-lite/data/agents'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      month_of: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      duration: '',
      search: '',
      no_of_days: '',
      status: '',
      type_of_loan: '',
      data: [],
      headers: [
        {text: 'Month Of', value: 'id', sortable: false},
        {text: 'Duration', value: 'date', sortable: false},
        {text: 'Working Days', value: 'name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPlusBox,
          mdiPencil,
          mdiAccountSearchOutline,
          mdiAccountClock,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.search_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['address', 'contact']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'trea']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['list_of_data_salary_working_days', 'create_salary_dates']),
      search_data() {
        this.saving = false
        this.list_of_data_salary_working_days({
          month_of: this.month_of,
        })
          .then(response => {
            this.data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true;
          if (parseFloat(this.no_of_days) > 31) {
            proceed = false;
            this.alert = true
            this.alert_message = 'No of days not exceed of 31 days'
            this.saving = false
          }
          if (proceed) {
            const data = new FormData()
            data.append('month_of', this.month_of)
            data.append('duration', this.duration)
            data.append('no_of_days', this.no_of_days)
            this.create_salary_dates(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.search_data()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
